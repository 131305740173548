import React from "react"

type Props = {
  className?: string
}

export const MainBlockPressPublicationsTechCrunch = ({ className }: Props) => (
  <div className={className}>
    <picture>
      <source
        width="213"
        height="29"
        srcSet={`/img/press/banner/techcrunch-logo-white@2x.png 2x, /img/press/banner/techcrunch-logo-white@3x.png 3x`}
      />
      <img
        src={`/img/press/banner/techcrunch-logo-white.png`}
        width="213"
        height="29"
        alt="TechCrunch"
      />
    </picture>
  </div>
)

export const MainBlockPressPublicationsLosAngelesBusinessJournal = ({
  className,
}: Props) => (
  <div className={className}>
    <picture>
      <source
        width="333"
        height="29"
        srcSet={`/img/press/banner/labusinessjournal@2x.png 2x, /img/press/banner/labusinessjournal@3x.png 3x`}
      />
      <img
        src={`/img/press/banner/labusinessjournal.png`}
        width="333"
        height="29"
        alt="LA Business Journal"
      />
    </picture>
  </div>
)

export const MainBlockPressPublicationsBuiltInLA = ({ className }: Props) => (
  <div className={className}>
    <picture>
      <source
        width="72"
        height="29"
        srcSet={`/img/press/banner/builtinla@2x.png 2x, /img/press/banner/builtinla@3x.png 3x`}
      />
      <img
        src={`/img/press/banner/builtinla.png`}
        width="72"
        height="29"
        alt="Built in LA"
      />
    </picture>
  </div>
)

export const MainBlockPressPublicationsOrangeCountyBusinessJournal = ({
  className,
}: Props) => (
  <div className={className}>
    <picture>
      <source
        width="388"
        height="29"
        srcSet={`/img/press/banner/ocbj-2@2x.png 2x, /img/press/banner/ocbj-2@3x.png 3x`}
      />
      <img
        src={`/img/press/banner/ocbj-2.png`}
        width="388"
        height="29"
        alt="Orange County Business Journal"
      />
    </picture>
  </div>
)

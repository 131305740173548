import { useContext } from "react"
import { default as braze } from "@braze/web-sdk"
import { BrazeContext } from "@providers"
import { JSONType } from "@types"

export type PublishBrazeEventTuple = [
  (eventName: string, eventProperties?: JSONType) => void,
  {
    brazeSdk: typeof braze
  }
]

export const usePublishBrazeEvent = (): PublishBrazeEventTuple => {
  // https://js.appboycdn.com/web-sdk/3.5/doc/modules/appboy.html
  const brazeContext = useContext(BrazeContext)

  const publishBrazeEvent = (eventName: string, eventProperties?: JSONType) => {
    brazeContext.brazeSdk.logCustomEvent(eventName, eventProperties)
  }

  return [publishBrazeEvent, { brazeSdk: brazeContext.brazeSdk }]
}

import { useContext } from "react"
import {
  osVersion,
  osName,
  fullBrowserVersion,
  browserName,
} from "react-device-detect"
import { v4 as uuidv4 } from "uuid"
import { TOKEN_SESSION_ID } from "@constants"
import { isBrowser, JSI } from "@libs"
import { storageGetItem, storageGetAuthToken } from "@session"
import { BranchContext } from "@providers"

let hardware = "undefined"
let urlAddress = ""
let vendor = ""
let fullBrowserName = browserName

if (isBrowser) {
  hardware = window.navigator?.platform || "undefined"
  urlAddress = window.location.href
  vendor = window.navigator.vendor
  fullBrowserName = vendor
    ? `${window.navigator.vendor} - ${browserName}`
    : browserName
}

export type headersType = {
  "X-Request-Id": string
  "X-Session-Id": string
  "X-Is-New-Session"?: boolean
  "X-Client-Udid": string
  "X-Client-Deviceadid"?: string
  "X-Event-Timestamp": number
  "X-Client-Ip-V4"?: string
  "X-Client-Ip-V6"?: string
  "X-Is-Wifi"?: boolean
  "X-Client-Os": string
  "X-Client-Hardware": string
  "X-Client-Platform": string
  "X-Client-Build": string
  "X-Client-App": string
  Authorization?: string
  "Content-Type": string
  Accept: string
  "X-Client-Browser": string
  "X-Client-Browser-Version": string
  "X-Current-Url": string
  "X-Version": string
}

export const useGetSessionHeaders = () => {
  const { getBranchBrowserFingerprintId } = useContext(BranchContext)

  return async () => {
    let token_auth = isBrowser ? storageGetAuthToken() : ""
    token_auth = typeof token_auth === "string" ? token_auth : ""

    const timestamp = Math.round(Date.now() / 1000)
    const session_id = isBrowser ? storageGetItem(TOKEN_SESSION_ID) || "" : ""

    // android or ios
    let platform = "web"
    if (JSI.isWebView) {
      if (JSI.isAndroid) {
        platform = "android"
      } else if (JSI.isIOS) {
        platform = "ios"
      } else {
        platform = "other"
      }
    }

    const udid = (await getBranchBrowserFingerprintId()) || ""

    const headers: headersType = {
      "X-Request-Id": uuidv4(),
      "X-Session-Id": typeof session_id === "string" ? session_id : "",
      "X-Client-Udid": udid,
      "X-Event-Timestamp": timestamp,
      "X-Client-Os": `${osName} - ${osVersion}`,
      "X-Client-Hardware": hardware,
      "X-Client-Platform": platform,
      "X-Client-Build": "1.0",
      "X-Client-App": "com.atm.web",
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Client-Browser": fullBrowserName,
      "X-Client-Browser-Version": fullBrowserVersion,
      "X-Current-Url": urlAddress,
      "X-Version": "1.0",
    }

    if (token_auth) {
      headers["Authorization"] = `Bearer ${token_auth}`
    }

    return headers
  }
}

import React from "react"

type Props = {
  className?: string
  name: string
}

export const MainBlockAboutHeadshotsImage = ({ className, name }: Props) => (
  <div className={className}>
    <picture>
      <source
        width="176"
        height="176"
        srcSet={`/img/2022q3/main-blocks/about-headshots/${name}@2x.png 2x, /img/2022q3/main-blocks/about-headshots/${name}@3x.png 3x`}
      />
      <img
        src={`/img/2022q3/main-blocks/about-headshots/${name}.png`}
        width="176"
        height="176"
        alt={name}
      />
    </picture>
  </div>
)

import React from "react"

type Props = {
  className?: string
}

export const MainBlockAboutEmpoweringImage = ({ className }: Props) => (
  <div className={className}>
    <picture>
      <source
        media="(max-width: 1055px)"
        width="327"
        height="224"
        srcSet="/img/2022q3/main-blocks/about-empowering/mobile.png, /img/2022q3/main-blocks/about-empowering/mobile@2x.png 2x, /img/2022q3/main-blocks/about-empowering/mobile@3x.png 3x"
      />
      <source
        media="(min-width: 1056px)"
        width="881"
        height="604"
        srcSet="/img/2022q3/main-blocks/about-empowering/desktop.png, /img/2022q3/main-blocks/about-empowering/desktop@2x.png 2x, /img/2022q3/main-blocks/about-empowering/desktop@3x.png 3x"
      />
      <img
        src="/img/2022q3/main-blocks/about-empowering/mobile.png"
        width="327"
        height="224"
        alt="Ants earn, save, and invest"
      />
    </picture>
  </div>
)

import { gql, useQuery } from "@apollo/client"
import { queryPromiseFactory } from "@services"
import { GQLUser } from "@types"

export const QUERY_USER = gql`
  query User {
    user {
      id
      dob
      email
      firstName
      lastName
      phone
      phoneConfirmedAt
      registrationCompletedAt
      token
    }
  }
`

import { gql } from "@apollo/client"
import { v4 as uuidv4 } from "uuid"
import { getBrowserQueryData } from "@libs"
import { mutationPromiseFactory, MutationTuple } from "@services"
import { JSONType } from "@types"

export type PublishWebEventVariables = {
  name: string
  data?: JSONType
  partnerOfferId?: string
  partnerId?: string
  requestId?: string
  servedImpressionId?: string
}

type PublishWebEventVariablesGQL = {
  event: PublishWebEventVariables
}

type PublishWebEventResults = {
  eventId: string
}

export const usePublishWebEvent = (): MutationTuple<
  PublishWebEventVariables,
  PublishWebEventResults
> => {
  const key = "publishWebEvent"

  const mutation = gql`
    mutation PublishWebEvent($input: PublishWebEventInput!) {
      ${key}(input: $input) {
        eventId
        clientMutationId
        errors {
          __typename
          ... on Error {
            message
          }
          ... on ClientEventError {
            errors {
              message
            }
          }
        }
      }
    }
  `
  const [mutationCall, data] = mutationPromiseFactory<
    PublishWebEventVariablesGQL,
    PublishWebEventResults
  >(mutation, key)

  const wrapperMutationCall = (variables: PublishWebEventVariables) => {
    if (typeof variables.requestId === "undefined") {
      variables.requestId = uuidv4()
    }
    if (typeof variables.data === "undefined") {
      variables.data = getBrowserQueryData()
    }

    return mutationCall({ event: variables })
  }

  return [wrapperMutationCall, data]
}

import React, { useState } from "react"
import { FormSelectProps, Form } from "react-bootstrap"

type SelectProps = FormSelectProps & {
  placeholder?: string
  touched?: boolean
  error?: string
  label?: string
  placeholderLabel?: boolean
}

export const Select = React.forwardRef((props: SelectProps, ref) => {
  const { touched, placeholderLabel, ...restProps } = props
  const isPlaceholdered = props.placeholderLabel && !props.value
  const isPlaceholderSelected =
    !props.placeholderLabel && props.placeholder && !!props.value

  return (
    <Form.Group
      controlId={props.name}
      className={`${props.className} select-field ${
        isPlaceholdered ? "is-placeholdered" : ""
      }`}
    >
      {props.label && (
        <Form.Label
          className={`field-label ${isPlaceholdered ? "mod-placeholder" : ""}`}
        >
          {props.label}
        </Form.Label>
      )}

      <Form.Select
        {...restProps}
        // @ts-ignore
        ref={ref}
        className={`primary-select ${
          isPlaceholderSelected ? " mod-placeholder" : ""
        }`}
        // onChange={(e) => {
        //   if (typeof props.onChange === "function") {
        //     props.onChange(e)
        //   }
        // }}
      >
        {(props.placeholder || props.placeholderLabel) && (
          <option className="placeholder" value="">
            {!props.placeholderLabel ? props.placeholder : ""}
          </option>
        )}
        {props.children}
      </Form.Select>
      {touched && props.error && (
        <Form.Control.Feedback className="field-error" type="invalid">
          {props.error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
})

import React, { useEffect } from "react"
import { default as braze } from "@braze/web-sdk"
import { storageGetUserId } from "@session"
import { isBrowser } from "@libs"

if (isBrowser) {
  braze.initialize(process.env.BRAZE_API_KEY || "", {
    baseUrl: process.env.BRAZE_SDK_ENDPOINT || "",
    allowUserSuppliedJavascript: true,
  })
  braze.display.automaticallyShowNewInAppMessages()
  braze.openSession()
}

export type BrazeContextType = {
  brazeSdk: typeof braze
}

export const BrazeContext = React.createContext<BrazeContextType>({
  brazeSdk: braze,
})

const defaultContext: BrazeContextType = {
  brazeSdk: braze,
}

export const BrazeProvider = (props: React.PropsWithChildren<{}>) => {
  useEffect(() => {
    const userId = storageGetUserId()
    if (userId) {
      braze.changeUser(userId)
    }
  }, [])

  return (
    <BrazeContext.Provider value={defaultContext}>
      {props.children}
    </BrazeContext.Provider>
  )
}

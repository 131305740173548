import { useEffect, useState } from "react"
import { navigate as gnavigate } from "gatsby"
import { NavigateOptions } from "@reach/router"
import { JSONType } from "@types"

export const isBrowser = typeof window !== "undefined"

export const navigate = function (
  to: string,
  options?: NavigateOptions<{}> | undefined
) {
  if (isBrowser) {
    gnavigate(to)
  }
}

export const useSafeEffect = (
  effect: React.EffectCallback,
  deps?: React.DependencyList | undefined
) => {
  useEffect(() => {
    let mounted = true

    if (mounted) {
      effect()
    }

    return function cleanup() {
      mounted = false
    }
  }, deps)
}

/**
 *
 * @param test function that returns whether the callback should run again
 * @param callback function to run once test is true
 * @param interval how often to test again
 */
export const callbackTester = (
  test: () => boolean,
  callback: () => void,
  interval = 200
) => {
  const run = () => {
    setTimeout(() => {
      if (test()) {
        callback()
      } else {
        run()
      }
    }, interval)
  }
  run()
}

export function formatPhoneNumber(
  phone: string | number | undefined,
  withInt: boolean = false
) {
  if (phone) {
    const cleaned = ("" + phone).replace(/\D/g, "")
    const match = cleaned.match(/^(\d)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      const intlCode = withInt && match[1] ? `+${match[1]} ` : ""
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("")
    }
  }

  return phone || ""
}

export function getBrowserQueryData() {
  if (!isBrowser) return undefined

  const params = new URLSearchParams(window.location.search)
  const obj: JSONType = {}

  // iterate over all keys
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key)
    } else {
      obj[key] = params.get(key)
    }
  }

  return obj
}

function getWindowDimensions(): [number, number] {
  if (typeof window === "undefined") {
    return [0, 0]
  }

  const { innerWidth: width, innerHeight: height } = window
  return [width, height]
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowDimensions
}

export function isMMPDisabled(pathname: string) {
  switch (location.pathname) {
    case "/privacy-policy/":
    case "/privacy-policy":
    case "/terms-of-service/":
    case "/terms-of-service":
    case "/sweepstakes/":
    case "/sweepstakes":
    case "/contact/":
    case "/contact":
    case "/faq/":
    case "/faq":
      // Do not fire MMP for these pages,
      // causes an attribution bug
      return true
  }

  return false
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

import React, { useContext } from "react"
import { isMobileOnly } from "react-device-detect"
import { Body1, Headline1, QRCodeWebToApp, Download } from "@components"
import { BranchContext } from "@providers"

export const RegistrationCompleteInfo = () => {
  const { downloadURL } = useContext(BranchContext)

  const AppButtons = () => {
    return (
      <Download
        showBothButtons
        style={{ textAlign: "center", width: "100%", maxWidth: "100%" }}
      />
    )
  }

  return (
    <div className="registration-complete-info">
      <Headline1 className="mbs-3">Congratulations!</Headline1>
      <Body1 className="mbs-3">
        Now it's time to start earning, saving, &amp; investing. Download the
        official ATM app to begin!
      </Body1>
      {isMobileOnly && (
        <>
          <div className="mbs-3">
            <img
              src="/img/ants-congrats-referred@3x.png"
              style={{ width: "290px", margin: "0 auto", display: "block" }}
            />
          </div>
          <div className="text-center"></div>
        </>
      )}

      {!isMobileOnly && (
        <>
          <Body1 className="mbs-3">
            Use your phone camera to scan the QR code below:{" "}
          </Body1>
          <div className="mbs-3">
            <QRCodeWebToApp />
          </div>
          <Body1 className="mbs-3">
            Also available on the{" "}
            <a target="_blank" href={downloadURL}>
              App Store
            </a>{" "}
            and{" "}
            <a target="_blank" href={downloadURL}>
              Google Play
            </a>
            :
          </Body1>
        </>
      )}

      <AppButtons />
    </div>
  )
}

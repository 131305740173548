import React, { useContext } from "react"
import { BranchContext } from "@providers"

import DownloadAppleWhite from "./download-apple-white.svg"
import DownloadAppleBlack from "./download-apple-black.svg"

type Props = {
  className?: string
  type?: string
}

export const ButtonDownloadApple = ({ className, type }: Props) => {
  const { downloadURL } = useContext(BranchContext)

  return (
    <div className={className}>
      <a href={downloadURL}>
        {type === "dark" || type === "black" ? (
          <DownloadAppleBlack />
        ) : (
          <DownloadAppleWhite />
        )}
      </a>
    </div>
  )
}

import { JSONType } from "@types"
import * as rxjs from "rxjs"
import { isBrowser } from "./utils"
import { isURL } from "@libs"

import { storageSetSessionExpiresAt, storageSetSessionID } from "@session"

declare global {
  interface Window {
    webkit: any
    JSI: any
    grecaptcha: any
    branch: any
  }
}

type JSIDispatch = {
  json?: JSONType
  done?: Function | string
}

type JSIJSONShareType = {
  text: string | null
  url: string | null
  title?: string | null
}

type JSITrackingExtraParam = {
  key: string
  value: string
}

type ISessionData = {
  session_id: string
  session_expires_at: string | number
}

const url_params = isBrowser
  ? new URLSearchParams(window.location.search)
  : null

const _isAndroid =
  (isBrowser && window.JSI && typeof window.JSI === "object") ||
  (url_params && url_params.get("__isAndroid"))
    ? true
    : false

const _isIOS =
  (isBrowser &&
    window.webkit &&
    window.webkit.messageHandlers &&
    typeof window.webkit.messageHandlers === "object") ||
  (url_params && url_params.get("__isIOS"))
    ? true
    : false

const _isWebView = _isAndroid || _isIOS

const _isObject = (obj: any) => {
  return obj === Object(obj)
}

const _ensureIsObject = (objOrJSON: JSIDispatch | undefined | string) => {
  let params = null

  if (_isObject(objOrJSON)) {
    params = objOrJSON
  } else if (typeof objOrJSON === "string") {
    try {
      params = JSON.parse(objOrJSON)
    } catch (e) {
      throw new Error("Invalid JSON passed in.")
    }
  }

  return params
}

const pageEventTypes = ["jsOnNativeBackPressed", "jsSetWebViewSession"]
const _pageEvents: any = {}
pageEventTypes.forEach((event) => (_pageEvents[event] = new rxjs.Subject()))

const _dispatch = (method: string, params?: JSIDispatch | string) => {
  let return_value = false
  const parsedParams = _ensureIsObject(params)

  if (typeof method !== "string" || !method) {
    throw new Error("name: is not a string")
  }

  let jsonPost = parsedParams && parsedParams.json ? parsedParams.json : "{}"
  jsonPost = JSON.stringify(jsonPost)

  try {
    if (_isAndroid && isBrowser && window.JSI[method]) {
      window.JSI[method](jsonPost)
      return_value = true
    } else if (_isIOS && isBrowser && window.webkit.messageHandlers[method]) {
      window.webkit.messageHandlers[method].postMessage(jsonPost)
      return_value = true
    } else {
      console.log("did not dispatch")
    }
  } catch (e) {
    console.log(e)
  }

  return return_value
}

const _parseJson = (json: string, done: (json: any) => void) => {
  try {
    const data = JSON.parse(json)
    if (typeof done === "function") {
      done(data)
    }
  } catch (e) {
    console.log(e)
  }
}

/**
 * This method is used to trigger a native app call with the typical method name/json object params.
 *
 * @param eventType string The name of the Native App method to call.
 * @returns function will return a function that takes in the name of the methodType to call, and an optional object that will be sent as JSON.
 *
 */
const _basicDispatchFactory = (eventType: string) => {
  return (params: JSIDispatch) => {
    return _dispatch(eventType, params)
  }
}

const JSI: { [key: string]: any } = {
  isIOS: _isIOS,
  isAndroid: _isAndroid,
  isWebView: _isWebView,

  /**
   *
   * @param event string The name of the event you are listening to
   * @param action function the callback to use
   */
  on: (event: string, action: (data: any) => void) => {
    if (typeof _pageEvents[event] !== "undefined") {
      _pageEvents[event].subscribe((v: any) => action(v))
    } else {
      const events = Object.keys(_pageEvents).join(`.\n`)
      throw `JSI: event '${event}' is not defined.\nAllowable events:\n${events}`
    }
  },
}

// ==========================================================================
// Mainly coming FROM native app
// ==========================================================================

JSI.jsOnNativeBackPressed = (json: string) => {
  _parseJson(json, (data) => {
    _pageEvents.jsOnNativeBackPressed.next(data)
  })
}

JSI.jsSetWebViewSession = (session_json: string) => {
  try {
    const session: ISessionData = JSON.parse(session_json)

    if (
      session &&
      _isObject(session) &&
      session.session_id &&
      session.session_expires_at
    ) {
      storageSetSessionID(session.session_id)
      storageSetSessionExpiresAt(session.session_expires_at)
      _pageEvents.jsSetWebViewSession.next(session)
    }
  } catch (e) {
    console.log(e)
  }
}

// ==========================================================================
// Mainly sent TO native app by web app
// ==========================================================================

JSI.jsOnShare = (params: JSIDispatch) => {
  const jsonPost =
    params && params.json
      ? <JSIJSONShareType>params.json
      : { text: null, url: null }

  const text = jsonPost.text
  const url = jsonPost.url
  const title = jsonPost.title

  if (typeof text !== "string" || !text) {
    throw new Error("text: Is not a string")
  }

  if (typeof url !== "string" || !url || !isURL(url)) {
    throw new Error("url: Is not a valid URL.")
  }

  if (title && typeof title !== "string") {
    throw new Error("title: Is not a string.")
  }

  return _dispatch("jsOnShare", params)
}

JSI.jsOnShareComplete = (params: JSIDispatch) => {
  const dispatched = _dispatch("jsOnShareComplete")

  if (params && typeof params.done === "function") {
    params.done({ dispatched })
  } else if (
    params.done &&
    typeof params.done === "string" &&
    isURL(params.done)
  ) {
    if (isBrowser) {
      window.location.href = params.done
    }
  }

  return dispatched
}

JSI.track = (
  eventName: string,
  eventTimestamp: number = 0,
  eventData: JSITrackingExtraParam[] | null = null
) => {
  if (typeof eventName !== "string") {
    throw "eventName: is not a string"
  }
  if (typeof eventTimestamp !== "number" || !eventTimestamp) {
    eventTimestamp = Date.now()
  }

  const paramsData: JSITrackingExtraParam[] | null = eventData

  const params: JSIDispatch = {
    json: {
      eventToken: eventName,
      eventTimestamp: eventTimestamp,
      params: paramsData || [],
    },
  }

  _dispatch("jsTrackLEAnalyticEvent", params)
}

// generate the basic dispatch events.
const simpleDispatchEvents = [
  "jsOnBackPressed",
  "jsOnGetWebViewSession",
  "jsOnLogout",
  "jsOnRegistrationComplete",
  "jsOnTokenInvalid",
  "jsSetUserIsCustodian",
  "jsTrackAdjustEvent",
  "jsShowLoading",
  "jsHideLoading",
]

simpleDispatchEvents.forEach(
  (event) => (JSI[event] = _basicDispatchFactory(event))
)

export default JSI

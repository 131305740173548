// @ts-ignore
import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

function SEO({
  lang,
  overrideTitleTemplate,
  title,
  canonical,
  description,
  image,
  article,
  schema,
  children,
}) {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            siteUrl
          }
        }
      }
    `
  )

  const { defaultTitle, siteUrl } = site.siteMetadata

  const seo = {
    title: title,
    canonical: canonical,
    description: description,
  }

  if (image) {
    seo.image = `${siteUrl}${image}`
  }

  if (pathname) {
    seo.url = `${siteUrl}${pathname}`
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seo.title}
      titleTemplate={overrideTitleTemplate || `${defaultTitle}: %s`}
      {...(seo.canonical
        ? {
            link: [
              { rel: "canonical", key: seo.canonical, href: seo.canonical },
            ],
          }
        : {})}
    >
      <meta name="twitter:card" content="summary_large_image" />
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && <meta name="description" content={seo.description} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta name="image" content={seo.image} />}
      {seo.image && <meta property="og:image" content={seo.image} />}
      {seo.image && <meta name="twitter:image" content={seo.image} />}

      <script>
        {`
          (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking qrCode".split(" "), 0);
          branch.init('${process.env.BRANCH_API_KEY}', function(err, data) {
            if (err) {
              console.error(err)
            }
            var event = new Event('branch_sdk_loaded')
            window.dispatchEvent(event)
          });
        `}
      </script>

      {schema && Object.keys(schema).length > 0 && (
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      )}

      {children}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  overrideTitleTemplate: null,
  canonical: null,
  description: null,
  image: null,
  article: false,
  schema: {},
  children: null,
}

SEO.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  overrideTitleTemplate: PropTypes.string,
  canonical: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
  schema: PropTypes.object,
  children: PropTypes.node,
}

export default SEO

import jwt from "jsonwebtoken"

import { TOKEN_AUTH } from "@constants"
import { storageSetItem, storageGetItem, storageRemoveItem } from "@session"

export const storageGetUserId = (): string | null => {
  let userId = null

  const token = storageGetAuthToken()
  if (!!token) {
    try {
      const payload = jwt.decode(token)
      if (
        payload &&
        typeof payload === "object" &&
        payload.sub &&
        typeof payload.sub === "string"
      ) {
        userId = payload.sub
      }
    } catch (e) {}
  }

  return userId
}

export const hasAuthToken = (): boolean => {
  const token = storageGetAuthToken()
  return !!token
}

export const storageSetAuthToken = (token: string) => {
  return storageSetItem(TOKEN_AUTH, token.replace("Bearer ", ""))
}

export const storageGetAuthToken = (): string | null => {
  const token = storageGetItem(TOKEN_AUTH)
  if (token && typeof token === "string") {
    return token
  }
  return null
}

export const storageRemoveAuthToken = () => {
  return storageRemoveItem(TOKEN_AUTH)
}

import React from "react"
import {
  ButtonGetTheApp,
  ButtonDownloadApple,
  ButtonDownloadGoogle,
} from "@components/buttons"
import "./style.scss"

type Props = {
  className?: string
  type?: string
  secondary?: boolean
  platformsOnly?: boolean
  platform?: "ios" | "android" | "both"
}

export const CallToActionButtons = ({
  className,
  type,
  secondary,
  platformsOnly,
  platform = "both",
}: Props) => (
  <div className={`call-to-action-buttons ${className || ""}`}>
    <div
      className={`cta-button-get ${
        platformsOnly ? "cta-platforms-only" : "cta-show-on-breakpoint"
      }`}
    >
      <ButtonGetTheApp secondary={secondary} />
    </div>
    <div
      className={`cta-button-platforms ${
        platformsOnly ? "cta-platforms-only" : "cta-show-on-breakpoint"
      }`}
    >
      {platform === "ios" || platform === "both" ? (
        <ButtonDownloadApple className="ios" type={type} />
      ) : null}
      {platform === "android" || platform === "both" ? (
        <ButtonDownloadGoogle className="android" type={type} />
      ) : null}
    </div>
  </div>
)

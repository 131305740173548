import { JSONType } from "@types"

import { isBrowser } from "@libs"

const NAMESPACE = `${process.env.APP_LOCAL_NAMESPACE || "am"}_`

const _key = (key: string) => `${NAMESPACE}${key}`

export const storageSetItem = (key: string, value: JSONType | string) => {
  if (!isBrowser || !value) {
    return
  }

  if (typeof value === "object") {
    value = JSON.stringify(value)
  }

  localStorage.setItem(_key(key), value)
}

export const storageGetItem = (key: string): string | object | null => {
  if (!isBrowser) {
    return ""
  }

  let value = localStorage.getItem(_key(key))

  if (typeof value === "string") {
    try {
      value = JSON.parse(value)
    } catch (e) {}
  }

  return value
}

export const storageRemoveItem = (key: string) => {
  if (!isBrowser) {
    return
  }

  localStorage.removeItem(_key(key))
}

export const storageClear = () => {
  const keys = Object.keys(localStorage)
  keys.forEach((key) => {
    if (key.indexOf(NAMESPACE) === 0) {
      localStorage.removeItem(key)
    }
  })
}

export * from "./auth"
export * from "./session"
export * from "./user-registration"

import React from "react"
import { Modal as RBModal, ModalProps as RBModalProps } from "react-bootstrap"

export interface ModalProps extends RBModalProps {
  title?: React.ReactNode
  body?: React.ReactNode
  footer?: React.ReactNode
  show: boolean
}

export const Modal = (props: ModalProps) => {
  return (
    <RBModal {...props}>
      {props.title && (
        <RBModal.Header closeButton>
          <RBModal.Title>{props.title}</RBModal.Title>
        </RBModal.Header>
      )}

      <RBModal.Body>{props.body || props.children}</RBModal.Body>

      {props.footer && <RBModal.Footer>{props.footer}</RBModal.Footer>}
    </RBModal>
  )
}

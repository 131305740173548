import React from "react"
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  HttpLink,
  concat,
} from "@apollo/client"
import { useGetSessionHeaders } from "@session"

export type ApolloClientProviderProps = {
  children?: React.ReactNode
}

export function ApolloClientProvider(props: ApolloClientProviderProps) {
  const getSessionHeaders = useGetSessionHeaders()
  const httpLink = new HttpLink({
    uri: process.env.API_URL,
  })

  const middleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
      return {
        headers: {
          ...headers,
          ...getSessionHeaders(),
        },
      }
    })

    return forward(operation)
  })

  const apollo_client = new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(middleware, httpLink),
  })

  return (
    <ApolloProvider client={apollo_client}>{props.children}</ApolloProvider>
  )
}

import React from "react"

type Props = {
  className?: string
}

export const MainBlockJoinColonyImage = ({ className }: Props) => (
  <div className={className}>
    <picture>
      <source media="(max-width: 1055px)" width="438" height="305" srcSet="/img/2022q3/main-blocks/join-colony/mobile.png, /img/2022q3/main-blocks/join-colony/mobile@2x.png 2x, /img/2022q3/main-blocks/join-colony/mobile@3x.png 3x" />
      <source media="(min-width: 1056px)" width="819" height="418" srcSet="/img/2022q3/main-blocks/join-colony/desktop.png, /img/2022q3/main-blocks/join-colony/desktop@2x.png 2x, /img/2022q3/main-blocks/join-colony/desktop@3x.png 3x" />
      <img src="/img/2022q3/main-blocks/join-colony/mobile.png" width="438" height="305" alt="Congratulations! You are now a member of the ATM colony." />
    </picture>
  </div>
)

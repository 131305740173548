import React from "react"

interface CP {
  variant?: null | undefined | "white"
  text?: string
  children?: React.ReactNode
  className?: string
  as?: string
}

const _builder = (props: CP, tag: string, className: string) => {
  className += props.variant ? ` mod-${props.variant}` : ""
  className += props.className ? ` ${props.className}` : ""
  const children = props.children ? props.children : props.text

  return React.createElement(props.as ? props.as : tag, { className }, children)
}

// Big Text
export const BiggestHeadline = (props: CP) =>
  _builder(props, "h1", "big-text mod-biggest")
export const BiggerHeadline = (props: CP) =>
  _builder(props, "h1", "big-text mod-bigger")
export const BigHeadline = (props: CP) =>
  _builder(props, "h1", "big-text mod-big")

// Headline
export const Headline1 = (props: CP) =>
  _builder(props, "h1", "headline-text mod-1")

export const Headline2 = (props: CP) =>
  _builder(props, "h2", "headline-text mod-2")

export const Headline3 = (props: CP) =>
  _builder(props, "h3", "headline-text mod-3")

export const SubHeadline = (props: CP) =>
  _builder(props, "h4", "headline-text mod-sub")

// Copy
export const Body1 = (props: CP) => _builder(props, "p", "body-text mod-1")

export const Body1Underline = (props: CP) =>
  _builder(props, "p", "body-text mod-1-underline")

export const Body2 = (props: CP) => _builder(props, "p", "body-text mod-2")

export const Body2Underline = (props: CP) =>
  _builder(props, "p", "body-text mod-2-underline")

// Label
export const Caption1 = (props: CP) =>
  _builder(props, "p", "caption-text mod-1")

export const Caption1Underline = (props: CP) =>
  _builder(props, "p", "caption-text mod-1 mod-underline")

export const Caption1Emphasis = (props: CP) =>
  _builder(props, "p", "caption-text mod-1 mod-emphasis")

export const Caption1EmphasisWide = (props: CP) =>
  _builder(props, "p", "caption-text mod-1 mod-emphasis mod-wide")

export const Caption2 = (props: CP) =>
  _builder(props, "p", "caption-text mod-2")

export const Caption2Underline = (props: CP) =>
  _builder(props, "p", "caption-text mod-2 mod-underline")

export const Caption2Emphasis = (props: CP) =>
  _builder(props, "p", "caption-text mod-2 mod-emphasis")

// Eyebrow
export const EyebrowRegular = (props: CP) =>
  _builder(props, "p", "eyebrow-text")

// Modal Dismis
export const ModalDismis = (props: CP) =>
  _builder(props, "p", "modal-dismiss-text")

import React, { useState, useContext } from "react"
import { Form, Alert } from "react-bootstrap"
import * as yup from "yup"
import {
  TextField,
  Body1,
  GQLErrorList,
  Select,
  Headline1,
  SubmitButton,
} from "@components"
import {
  CONTINENTAL_US_STATES,
  TRACKING_ATM,
  TRACKING_BRANCH,
  TRACKING_BRAZE,
} from "@constants"
import { isZipCode } from "@libs"
import {
  useAddress,
  usePublishWebEvent,
  usePublishBrazeEvent,
  useRegistrationComplete,
} from "@services"
import {
  storageGetUserRegistration,
  storageSetUserRegistration,
  storageRemoveUserRegistration,
} from "@session"

import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { usePageFlow, BranchContext } from "@providers"

const formSchema = yup.object().shape({
  address1: yup.string().required("Required"),
  address2: yup.string(),
  locality: yup.string().required("Required"),
  administrativeArea: yup.string().required("Required"),
  postalCode: yup
    .string()
    .required("Required")
    .test({
      name: "zip-valid",
      message: "Invalid zip code",
      test: (value) => {
        return isZipCode(value)
      },
    }),
})

export const AddressForm = () => {
  const pageFlow = usePageFlow()
  const [address] = useAddress()
  const [registrationComplete] = useRegistrationComplete()
  const [publishWebEvent] = usePublishWebEvent()
  const [publishBrazeEvent] = usePublishBrazeEvent()
  const { publishBranchEvent } = useContext(BranchContext)

  const [errorMessage, setErrorMessage] = useState<null | React.ReactNode>(null)

  const storedValues = storageGetUserRegistration()
  const initialValues = {
    address1: storedValues.address1 || "",
    address2: storedValues.address2 || "",
    locality: storedValues.locality || "",
    administrativeArea: storedValues.administrativeArea || "",
    postalCode: storedValues.postalCode || "",
  }

  const {
    control,
    handleSubmit,
    formState: { errors, touchedFields, isValid, isSubmitting },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    criteriaMode: "all",
    defaultValues: initialValues,
    resolver: yupResolver(formSchema),
  })

  const handleOnSubmit = (values: any) => {
    return new Promise<void>((resolve, reject) => {
      const storedValues = Object.assign({}, values)
      storageSetUserRegistration(storedValues)

      publishWebEvent({ name: TRACKING_ATM.button_tap_reg_address_next })

      address({
        userAddress: {
          address1: values.address1,
          address2: values.address2,
          locality: values.locality,
          administrativeArea: values.administrativeArea,
          postalCode: values.postalCode,
        },
      }).then(() => {
        registrationComplete({})
          .then(() => {
            publishBranchEvent(TRACKING_BRANCH.registration_complete)
            publishBrazeEvent(TRACKING_BRAZE.registration_complete)

            storageRemoveUserRegistration()
            pageFlow.navigateNext()
          })
          .catch((errors) => {
            setErrorMessage(<GQLErrorList errors={errors} />)
            resolve()
          })
      })
    })
  }

  return (
    <Form
      noValidate
      onSubmit={handleSubmit(async (data) => await handleOnSubmit(data))}
    >
      <Headline1 className="mbs-3">Verify your address</Headline1>
      <Body1 className="mbs-2">We need a few more details from you...</Body1>

      {errorMessage && (
        <Alert variant="danger" className="mbs-3">
          {errorMessage}
        </Alert>
      )}

      <Controller
        name="address1"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Home address*"
            placeholderLabel
            className="mbs-2"
            isInvalid={touchedFields.address1 && !!errors.address1?.message}
            error={errors.address1?.message}
            touched={touchedFields.address1}
          />
        )}
      />

      <Controller
        name="address2"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Unit/apt (optional)"
            placeholderLabel
            className="mbs-2"
            isInvalid={touchedFields.address2 && !!errors.address2?.message}
            error={errors.address2?.message}
            touched={touchedFields.address2}
          />
        )}
      />

      <Controller
        name="locality"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="City*"
            placeholderLabel
            className="mbs-2"
            isInvalid={touchedFields.locality && !!errors.locality?.message}
            error={errors.locality?.message}
            touched={touchedFields.locality}
          />
        )}
      />

      <Controller
        name="administrativeArea"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            label="Select state"
            placeholderLabel
            className="mbs-2"
            isInvalid={
              touchedFields.administrativeArea &&
              !!errors.administrativeArea?.message
            }
            error={errors.administrativeArea?.message}
            touched={touchedFields.administrativeArea}
          >
            {CONTINENTAL_US_STATES.map((state, i) => (
              <option key={i} value={state.code}>
                {state.abbreviation}
              </option>
            ))}
          </Select>
        )}
      />

      <Controller
        name="postalCode"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            onKeyPress={(e) => {
              if (!/[0-9-]/.test(e.key)) {
                e.preventDefault()
              }
            }}
            label="Zip code*"
            placeholderLabel
            className="mbs-2"
            isInvalid={touchedFields.postalCode && !!errors.postalCode?.message}
            error={errors.postalCode?.message}
            touched={touchedFields.postalCode}
          />
        )}
      />

      <SubmitButton
        disabled={!isValid || isSubmitting}
        variant="primary2"
        className="mts-1"
        isSubmitting={isSubmitting}
      >
        Next
      </SubmitButton>
    </Form>
  )
}

import React, { ForwardedRef, useState } from "react"
import { Form, InputGroup } from "react-bootstrap"

import IconEyeOpen from "@images/icons/general/EyeOpen.svg"
import IconEyeClosed from "@images/icons/general/EyeClosed.svg"

export type TextFieldProps = {
  name: string
  label?: string
  placeholder?: string
  onChange?: (e: any) => void
  onBlur?: (e: any) => void
  onFocus?: (e: any) => void
  onKeyPress?: (e: any) => void
  value: string
  touched?: boolean
  error?: string
  isInvalid?: boolean
  type?: string
  subtext?: React.ReactNode
  className?: string
  placeholderLabel?: boolean
  maxLength?: number
  ref?: ForwardedRef<any>
}

export const TextField = React.forwardRef((props: TextFieldProps, ref) => {
  const [isFocused, setIsFocused] = useState(false)
  const isPlaceholdered = props.placeholderLabel && !isFocused && !props.value
  const [passwordVisible, setPasswordVisible] = useState(false)

  const FormControl = (props: TextFieldProps) => (
    <Form.Control
      className={`mod-transparent-bg field-text ${
        props.className ? props.className : ""
      }`}
      type={props.type}
      name={props.name}
      placeholder={props.placeholder}
      onChange={props.onChange}
      onKeyPress={props.onKeyPress}
      maxLength={props.maxLength}
      onBlur={(e: any) => {
        setIsFocused(false)
        if (typeof props.onBlur === "function") {
          props.onBlur(e)
        }
      }}
      onFocus={(e: any) => {
        setIsFocused(true)
        if (typeof props.onFocus === "function") {
          props.onFocus(e)
        }
      }}
      value={props.value || ""}
      isInvalid={props.isInvalid}
    ></Form.Control>
  )

  const MemoInputGroup = React.useMemo(
    () => (props: TextFieldProps) => {
      let input = <FormControl {...props} />
      switch (props.type) {
        case "password":
          input = (
            <InputGroup className={props.isInvalid ? "is-invalid" : ""}>
              <FormControl
                {...props}
                type={passwordVisible ? "text" : props.type}
              />
              <span
                className="icon"
                onClick={() => setPasswordVisible(!passwordVisible)}
              >
                {passwordVisible ? <IconEyeOpen /> : <IconEyeClosed />}
              </span>
            </InputGroup>
          )
          break

        case "date":
          input = <FormControl {...props} />
          break
      }

      return input
    },
    [passwordVisible]
  )

  return (
    <Form.Group
      controlId={props.name}
      className={`form-group text-field mod-${props.type || "text"} ${
        isPlaceholdered ? "is-placeholdered" : ""
      } ${props.className ? props.className : ""} `}
    >
      {props.label && (
        <Form.Label
          className={`field-label ${isPlaceholdered ? "mod-placeholder" : ""}`}
        >
          {props.label}
        </Form.Label>
      )}

      <MemoInputGroup {...props} className={`field-text`} />

      {!!props.subtext && <>{props.subtext}</>}
      {props.touched && props.error && (
        <Form.Control.Feedback className="field-error" type="invalid">
          {props.error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
})

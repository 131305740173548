import React from "react"
import { ModalProps } from "react-bootstrap"

import { Modal } from "@components"

export const ToastModal = (props: ModalProps) => {
  const className =
    "toast-modal" + (props.className ? ` ${props.className}` : "")
  return (
    <Modal
      {...props}
      show={props.show || false}
      className={className}
      centered
    />
  )
}

import { Caption1, ToastModal } from "@components"
import { useResendPhoneVerification } from "@services"
import React, { MouseEvent, useState } from "react"

export type ResendPhoneVerificationCodeLinkProps = {
  className?: string
  children?: React.ReactNode
  onSuccess?: (data?: any) => void
  onError?: (errors?: any) => void
}

export const ResendPhoneVerificationCodeLink = (
  props: ResendPhoneVerificationCodeLinkProps
) => {
  const SUCCESS_TEXT = "Your code has been resent to your phone."
  const ERROR_TEXT = "Error sending code. "

  const [modalText, setModalText] = useState(SUCCESS_TEXT)
  const [showModal, setShowModal] = useState(false)
  const [resendPhoneVerification] = useResendPhoneVerification()

  const triggerModal = (error?: string) => {
    setModalText(error ? ERROR_TEXT + " " + error : SUCCESS_TEXT)
    setShowModal(true)
    setTimeout(() => {
      setShowModal(false)
    }, 2000)
  }

  const handleResendPhoneVerification = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    resendPhoneVerification({})
      .then((data) => {
        triggerModal()
        if (props.onSuccess) {
          props.onSuccess(data)
        }
      })
      .catch((errors) => {
        triggerModal("error")
        if (props.onError) {
          props.onError(errors)
        }
      })
  }

  return (
    <>
      <a
        href="#resend-code"
        className={props.className}
        onClick={handleResendPhoneVerification}
      >
        {props.children || "Resend code"}
      </a>
      <ToastModal
        show={showModal}
        centered
        style={{
          textAlign: "center",
        }}
      >
        <Caption1>{modalText}</Caption1>
      </ToastModal>
    </>
  )
}

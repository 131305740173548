import React, { useState, useContext } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { Form, Alert } from "react-bootstrap"
import { isMobileOnly } from "react-device-detect"
import { Controller, useForm } from "react-hook-form"
import * as yup from "yup"
import {
  EmailCorrectModal,
  TextField,
  Body1,
  Caption1,
  Headline1,
  GQLErrorList,
  SubmitButton,
  AntDancingCashMobileImage,
  Checkbox,
} from "@components"
import {
  REGEX_PASSWORD,
  REGEX_PHONE,
  TRACKING_ATM,
  TRACKING_BRANCH,
  TRACKING_BRAZE,
  URL_ATM_PRIVACY_POLICY,
  URL_ATM_TERMS,
} from "@constants"
import {
  useCreateUser,
  usePublishWebEvent,
  usePublishBrazeEvent,
} from "@services"
import {
  storageGetUserRegistration,
  storageSetAuthToken,
  storageSetUserRegistration,
} from "@session"
import { usePageFlow, BranchContext } from "@providers"
import { GQLError } from "@types"
import { capitalizeFirstLetter } from "@libs"

export const SignupForm = () => {
  const pageFlow = usePageFlow()
  const [showModal, setShowModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState<null | React.ReactNode>(null)
  const [confirmChecked, setConfirmChecked] = useState(false)
  const [publishWebEvent] = usePublishWebEvent()
  const [publishBrazeEvent] = usePublishBrazeEvent()
  const { publishBranchEvent } = useContext(BranchContext)

  const [createUser] = useCreateUser()
  const [emailErrorMessage, setEmailErrorMessage] = useState("")
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("")

  const formSchema = yup.object().shape({
    phone: yup
      .string()
      .required("Required")
      .matches(REGEX_PHONE, "Valid phone number required"),
    email: yup.string().required("Required").email("Valid email required."),
    password: yup
      .string()
      .required("Required")
      .matches(
        REGEX_PASSWORD,
        "Password must contain uppercase, lowercase, 8 characters minimum and at least 1 number"
      ),
  })

  const storedValues = storageGetUserRegistration()
  const initialValues = {
    phone: storedValues.phone || "",
    phone_code: storedValues.phoneCode || "",
    password: "",
    email: storedValues.email || "",
    confirm: "",
  }

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, touchedFields, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    criteriaMode: "all",
    defaultValues: initialValues,
    resolver: yupResolver(formSchema),
  })

  const handleOnSubmit = (values: any) => {
    storageSetUserRegistration(values)
    publishWebEvent({ name: TRACKING_ATM.button_tap_reg_next })
    setShowModal(true)
    setPhoneErrorMessage("")
    setEmailErrorMessage("")
    setErrorMessage(null)
  }

  const values = getValues()

  return (
    <Form noValidate onSubmit={handleSubmit(handleOnSubmit)}>
      {isMobileOnly && <AntDancingCashMobileImage className="mbs-3" />}
      <Headline1 className="mbs-3">Sign up with ATM</Headline1>
      <Body1 className="mbs-2">It's fast and easy to use</Body1>

      {errorMessage && (
        <Alert variant="danger" className="mbs-3">
          {errorMessage}
        </Alert>
      )}

      <Controller
        name="phone"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Mobile Number*"
            className="mbs-2"
            placeholderLabel
            isInvalid={
              (touchedFields.phone && !!errors.phone) || !!phoneErrorMessage
            }
            error={errors.phone?.message || phoneErrorMessage}
            touched={touchedFields.phone || !!phoneErrorMessage}
            onKeyPress={() => {
              !!phoneErrorMessage && setPhoneErrorMessage("")
            }}
          />
        )}
      />

      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Email*"
            className="mbs-2"
            placeholderLabel
            isInvalid={
              (touchedFields.email && !!errors.email) || !!emailErrorMessage
            }
            error={errors.email?.message || emailErrorMessage}
            touched={touchedFields.email || !!emailErrorMessage}
            onKeyPress={() => {
              !!emailErrorMessage && setEmailErrorMessage("")
            }}
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="password"
            label="Password*"
            className="mbs-2"
            placeholderLabel
            isInvalid={touchedFields.password && !!errors.password}
            error={errors.password?.message}
            touched={touchedFields.password}
          />
        )}
      />

      <Checkbox
        className="mbs-3"
        id="confirm"
        name="confirm"
        type="checkbox"
        label={
          <Caption1>
            Confirmation*: I confirm that I am 18 years or older and agree to
            the{" "}
            <a target="_blank" href={URL_ATM_PRIVACY_POLICY}>
              privacy policy
            </a>{" "}
            and{" "}
            <a target="_blank" href={URL_ATM_TERMS}>
              terms of service
            </a>
          </Caption1>
        }
        checked={confirmChecked}
        onChange={(e) => setConfirmChecked(e.target.checked)}
      />

      <input type="hidden" name="phone_code" value={values.phone_code} />
      <SubmitButton disabled={!confirmChecked || !isValid} variant="primary2">
        Next
      </SubmitButton>

      <EmailCorrectModal
        show={showModal}
        email={values.email}
        onShow={() => {
          publishWebEvent({ name: TRACKING_ATM.screen_view_reg_email_modal })
        }}
        onCanceled={() => {
          setShowModal(false)
        }}
        onConfirmed={() => {
          publishWebEvent({ name: TRACKING_ATM.button_tap_reg_email_next })
          createUser({
            phone: {
              code: Number(values.phone_code),
              number: values.phone,
            },
            email: values.email,
            password: values.password,
          })
            .then((data) => {
              publishBranchEvent(TRACKING_BRANCH.registration)
              publishBrazeEvent(TRACKING_BRAZE.registration)

              if (data.user.token) {
                storageSetAuthToken(data.user.token)
                pageFlow.navigateNext()
              } else {
                throw [{ message: "Undefined token" }]
              }
            })
            .catch((errors: GQLError[]) => {
              if (errors && errors.length) {
                let others: GQLError[] = []

                errors.forEach((err) => {
                  switch (err.message) {
                    case "email has already been taken":
                      setEmailErrorMessage(capitalizeFirstLetter(err.message))
                      break
                    case "phone has already been taken":
                      setPhoneErrorMessage(capitalizeFirstLetter(err.message))
                      break
                    default:
                      others.push(err)
                  }
                })

                others.length &&
                  setErrorMessage(<GQLErrorList errors={others} />)
              }
              setShowModal(false)
            })
        }}
      />
    </Form>
  )
}

const today = new Date()
const _dobYears: number[] = []
let dobYear = today.getUTCFullYear()
while (1900 <= dobYear) {
  _dobYears.push(dobYear)
  dobYear -= 1
}

export const DOB_YEARS = _dobYears
export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]

// Countries
export const CONTINENTAL_US_STATES = [
  { code: "US-AL", name: "Alabama", abbreviation: "AL" },
  { code: "US-AK", name: "Alaska", abbreviation: "AK" },
  { code: "US-AZ", name: "Arizona", abbreviation: "AZ" },
  { code: "US-AR", name: "Arkansas", abbreviation: "AR" },
  { code: "US-CA", name: "California", abbreviation: "CA" },
  { code: "US-CO", name: "Colorado", abbreviation: "CO" },
  { code: "US-CT", name: "Connecticut", abbreviation: "CT" },
  { code: "US-DE", name: "Delaware", abbreviation: "DE" },
  { code: "US-FL", name: "Florida", abbreviation: "FL" },
  { code: "US-GA", name: "Georgia", abbreviation: "GA" },
  { code: "US-HI", name: "Hawaii", abbreviation: "HI" },
  { code: "US-ID", name: "Idaho", abbreviation: "ID" },
  { code: "US-IL", name: "Illinois", abbreviation: "IL" },
  { code: "US-IN", name: "Indiana", abbreviation: "IN" },
  { code: "US-IA", name: "Iowa", abbreviation: "IA" },
  { code: "US-KS", name: "Kansas", abbreviation: "KS" },
  { code: "US-KY", name: "Kentucky", abbreviation: "KY" },
  { code: "US-LA", name: "Louisiana", abbreviation: "LA" },
  { code: "US-ME", name: "Maine", abbreviation: "ME" },
  { code: "US-MD", name: "Maryland", abbreviation: "MD" },
  { code: "US-MA", name: "Massachusetts", abbreviation: "MA" },
  { code: "US-MI", name: "Michigan", abbreviation: "MI" },
  { code: "US-MN", name: "Minnesota", abbreviation: "MN" },
  { code: "US-MS", name: "Mississippi", abbreviation: "MS" },
  { code: "US-MO", name: "Missouri", abbreviation: "MO" },
  { code: "US-MT", name: "Montana", abbreviation: "MT" },
  { code: "US-NE", name: "Nebraska", abbreviation: "NE" },
  { code: "US-NV", name: "Nevada", abbreviation: "NV" },
  { code: "US-NH", name: "New Hampshire", abbreviation: "NH" },
  { code: "US-NJ", name: "New Jersey", abbreviation: "NJ" },
  { code: "US-NM", name: "New Mexico", abbreviation: "NM" },
  { code: "US-NY", name: "New York", abbreviation: "NY" },
  { code: "US-NC", name: "North Carolina", abbreviation: "NC" },
  { code: "US-ND", name: "North Dakota", abbreviation: "ND" },
  { code: "US-OH", name: "Ohio", abbreviation: "OH" },
  { code: "US-OK", name: "Oklahoma", abbreviation: "OK" },
  { code: "US-OR", name: "Oregon", abbreviation: "OR" },
  { code: "US-PA", name: "Pennsylvania", abbreviation: "PA" },
  { code: "US-RI", name: "Rhode Island", abbreviation: "RI" },
  { code: "US-SC", name: "South Carolina", abbreviation: "SC" },
  { code: "US-SD", name: "South Dakota", abbreviation: "SD" },
  { code: "US-TN", name: "Tennessee", abbreviation: "TN" },
  { code: "US-TX", name: "Texas", abbreviation: "TX" },
  { code: "US-UT", name: "Utah", abbreviation: "UT" },
  { code: "US-VT", name: "Vermont", abbreviation: "VT" },
  { code: "US-VA", name: "Virginia", abbreviation: "VA" },
  { code: "US-WA", name: "Washington", abbreviation: "WA" },
  { code: "US-WV", name: "West Virginia", abbreviation: "WV" },
  { code: "US-WI", name: "Wisconsin", abbreviation: "WI" },
  { code: "US-WY", name: "Wyoming", abbreviation: "WY" },
  { code: "US-DC", name: "District of Columbia", abbreviation: "DC" },
]

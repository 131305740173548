import { isBrowser } from "@libs"

export const replaceUserId = (html: string) => {
  const userId = isBrowser
    ? new URLSearchParams(window.location.search).get("user_id")
    : ""
  return html.replace(/{{user_id}}/g, userId || "")
}

export const replaceLinkPlaceholder = (
  html: string,
  link: string,
  placeholder: string
) => {
  try {
    const urlObj = new URL(link)

    if (isBrowser) {
      const userId = new URLSearchParams(window.location.search).get("user_id")
      if (userId) {
        urlObj.searchParams.set("user_id", userId)
      }
    }

    return html.replace(`href="${placeholder}"`, `href="${urlObj.href}"`)
  } catch (e) {
    console.error("Invalid link provided:", link)
    return html
  }
}

import React, { useContext, useEffect, useState } from "react"
import { isBrowser, navigate } from "@libs"
import { hasAuthToken } from "@session"

export type PageFlow = {
  pathname: string
  noAuthLocation?: string
  allowNoAuth?: boolean
  progressBar?: {
    show: boolean
    step: number
    totalSteps: number
  }

  [key: string]: any
}

type PageFlowAPI = {
  updateFlowSettings: (flowSettings: PageFlow[]) => void
  getCurrentFlow: () => PageFlow | undefined
  navigateNext: (fallbackPathname?: string) => void
  navigateAuth: () => boolean
}

const defaultAPI = {
  updateFlowSettings: (flow: PageFlow[]) => {},
  getCurrentFlow: (): PageFlow | undefined => {
    return undefined
  },
  navigateNext: (fallbackPathname?: string) =>
    navigate(fallbackPathname || "/"),
  navigateAuth: () => {
    return false
  },
}

export const PageFlowContext = React.createContext(defaultAPI)

export type PageFlowProviderProps = {
  settings: PageFlow[]
}
export const PageFlowProvider = (
  props: React.PropsWithChildren<PageFlowProviderProps>
) => {
  const [api, setApi] = useState<PageFlowAPI>(defaultAPI)
  const [flowSettings, setFlowSettings] = useState<PageFlow[]>(props.settings)

  const pathname = isBrowser ? window.location.pathname : ""

  let currentFlow = flowSettings.find((f) => f.pathname === pathname)

  useEffect(() => {
    if (isBrowser) {
      api.updateFlowSettings = (flowSettings) => {
        setFlowSettings(flowSettings)
      }

      api.getCurrentFlow = () => {
        return currentFlow
      }

      api.navigateNext = (fallbackPathname) => {
        const currentIndex = flowSettings.findIndex(
          (s) => s.pathname === pathname
        )
        const next = flowSettings[currentIndex + 1]
        const goTo = !!fallbackPathname
          ? fallbackPathname
          : !!next
          ? next.pathname
          : "/"
        navigate(goTo)
      }

      api.navigateAuth = () => {
        if (!currentFlow || (!currentFlow.allowNoAuth && !hasAuthToken)) {
          navigate("/")
          return true
        }

        return false
      }

      setApi(api)
    }
  }, [])

  return (
    <PageFlowContext.Provider value={api}>
      {props.children}
    </PageFlowContext.Provider>
  )
}

export const usePageFlow = () => {
  const pageFlow = useContext(PageFlowContext)
  return pageFlow
}

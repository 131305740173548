import {
  TOKEN_AUTH,
  TOKEN_SESSION_ID,
  SESSION_EXP_TIMEOUT_MSECONDS,
  TOKEN_SESSION_EXPIRES_AT,
} from "@constants"
import { storageSetItem, storageGetItem, storageRemoveItem } from "@session"
import { v4 as uuidv4 } from "uuid"
import { isBrowser, JSI } from "@libs"
import { storageSetAuthToken } from "."

// Specific setter/getters
const urlParams = new URLSearchParams(isBrowser ? window.location.search : {})

/**
 * Sets up a local session_id (for non WebView pages) if one does not exist
 * or if it is expired.
 */
const _is_session_expired = (
  timestamp: number | string | null,
  now: number | string | null
) => {
  timestamp = typeof timestamp !== "number" ? Number(timestamp) : timestamp
  now = typeof timestamp !== "number" ? Number(now) : now

  if (!timestamp) {
    return false
  }

  now = now || Date.now()
  timestamp = Number(timestamp)

  return timestamp < now
}

const _init_local_session_id = () => {
  const now = Date.now()
  const session_id = storageGetSessionID()
  const _session_expires_at = storageGetSessionExpiresAt()
  const session_expires_at =
    _session_expires_at === "string" ? Number(_session_expires_at) : null

  if (
    !session_id ||
    !session_expires_at ||
    _is_session_expired(session_expires_at, now)
  ) {
    storageSetSessionID(uuidv4())

    const future = now + SESSION_EXP_TIMEOUT_MSECONDS
    storageSetSessionExpiresAt(future)
  }
}

export const storageInitQueryKeys = () => {
  if (!isBrowser) {
    return
  }

  if (JSI.isWebView) {
    // Web view may pass in some data we want to set
    const token = urlParams.get(TOKEN_AUTH) // token => elsi in local storage
    const session_id = urlParams.get(TOKEN_SESSION_ID)
    const session_expires_at = urlParams.get(TOKEN_SESSION_EXPIRES_AT)

    if (token) {
      window.localStorage.setItem(TOKEN_AUTH, token)
      storageSetAuthToken(token)
    }

    if (session_id) {
      storageSetSessionID(session_id)
    }

    if (session_expires_at) {
      storageSetSessionExpiresAt(session_expires_at)
    }
  } else {
    // means we are in the web app via browser
    _init_local_session_id()
  }
}

// Session ID
export const storageSetSessionID = (sessionID: string) => {
  return storageSetItem(TOKEN_SESSION_ID, sessionID)
}

export const storageGetSessionID = () => {
  return storageGetItem(TOKEN_SESSION_ID)
}

export const storageRemoveSessionID = () => {
  return storageRemoveItem(TOKEN_SESSION_ID)
}

// Session Expiration
export const storageSetSessionExpiresAt = (time: string | number) => {
  return storageSetItem(TOKEN_SESSION_EXPIRES_AT, String(time))
}

export const storageGetSessionExpiresAt = () => {
  return storageGetItem(TOKEN_SESSION_EXPIRES_AT)
}

export const storageRemoveSessionExpiresAt = () => {
  return storageRemoveItem(TOKEN_SESSION_EXPIRES_AT)
}

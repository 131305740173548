import React, { useState, useEffect, createContext } from "react"
import { DEEPVIEW_BRANCH, URL_BRANCH_WEB_TO_APP } from "@constants"
import { isBrowser } from "@libs"
import { storageGetUserId } from "@session"
import { BranchData, BranchDataType } from "@types"

type Branch = typeof window.branch

type BranchContextType = {
  downloadURL: string
  getBranch: () => Promise<Branch>
  getBranchBrowserFingerprintId: () => Promise<string | undefined>
  setBranchIdentity: (userId: string) => Promise<void>
  publishBranchEvent: (
    eventName: string,
    eventProperties?: BranchDataType
  ) => Promise<void>
  trackBranch: (
    eventName: string,
    eventProperties?: BranchDataType
  ) => Promise<void>
}

type DataType = {
  $og_title?: string
  $og_description?: string
  $og_image_url?: string
  $og_video?: string
  $og_url?: string
  $og_redirect?: string
  $deeplink_path?: string
  $desktop_deepview?: string
}

export const BranchContext = createContext<BranchContextType>({
  downloadURL: URL_BRANCH_WEB_TO_APP,
  getBranch: () => Promise.reject("Branch SDK is not available"),
  getBranchBrowserFingerprintId: () => Promise.resolve(undefined),
  setBranchIdentity: () => Promise.resolve(),
  publishBranchEvent: () => Promise.resolve(),
  trackBranch: () => Promise.resolve(),
})

export const BranchProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [downloadURL, setDownloadURL] = useState(URL_BRANCH_WEB_TO_APP)

  useEffect(() => {
    const fetchData = async () => {
      if (isBrowser) {
        try {
          const userId = storageGetUserId()
          if (userId) {
            await setBranchIdentity(userId)
          }
          await generateDownloadLink({
            // $desktop_deepview: DEEPVIEW_BRANCH.desktop_default,
          })
        } catch (e) {
          console.error("Error in fetchData: ", e)
        }
      }
    }

    fetchData()
  }, [])

  const getBranch = (): Promise<Branch> => {
    return new Promise((resolve, reject) => {
      if (isBrowser && window?.branch) {
        resolve(window.branch)
      } else if (isBrowser) {
        window.addEventListener("branch_sdk_loaded", () => {
          resolve(window.branch)
        })
      } else {
        reject("Branch SDK only available in web browser")
      }
    })
  }

  const generateDownloadLink = async (data?: DataType): Promise<void> => {
    const branchData: BranchData = {
      $og_title: data?.$og_title || null,
      $og_description: data?.$og_description || null,
      $og_image_url: data?.$og_image_url || null,
      $og_video: data?.$og_video || null,
      $og_url: data?.$og_url || null,
      $og_redirect: data?.$og_redirect || null,
      $deeplink_path: data?.$deeplink_path || null,
      $desktop_deepview: data?.$desktop_deepview || null,
    }

    Object.keys(branchData).forEach(
      (key) => branchData[key] == null && delete branchData[key]
    )

    try {
      const branch = await getBranch()

      return new Promise((resolve, reject) => {
        try {
          branch.link(branchData, (err: any, url: string) => {
            if (err || !url) {
              console.error("Branch link could not be created")
              setDownloadURL(URL_BRANCH_WEB_TO_APP)
              reject(err)
            } else {
              setDownloadURL(url)
              resolve()
            }
          })
        } catch (err) {
          reject(err)
        }
      })
    } catch (e) {
      console.error("Error generating download link: ", e)
      setDownloadURL(URL_BRANCH_WEB_TO_APP)
      return Promise.reject(e)
    }
  }

  const getBranchBrowserFingerprintId = async (): Promise<
    string | undefined
  > => {
    try {
      const branch = await getBranch()

      return new Promise((resolve, reject) => {
        try {
          branch.getBrowserFingerprintId((err: any, data: any) => {
            return err ? reject(err) : resolve(data)
          })
        } catch (err) {
          reject(err)
        }
      })
    } catch (e) {
      console.error("Error getting branch browser fingerprint id: ", e)
    }
  }

  const setBranchIdentity = async (userId: string): Promise<void> => {
    try {
      const branch = await getBranch()

      return new Promise((resolve, reject) => {
        try {
          branch.setIdentity(userId, (err: any, data: any) => {
            return err ? reject(err) : resolve()
          })
        } catch (err) {
          reject(err)
        }
      })
    } catch (e) {
      console.error("Error setting branch identity: ", e)
    }
  }

  const publishBranchEvent = async (
    eventName: string,
    eventProperties?: BranchDataType
  ): Promise<void> => {
    try {
      const branch = await getBranch()

      return new Promise((resolve, reject) => {
        try {
          branch.logEvent(eventName, eventProperties || {}, (err: any) => {
            return err ? reject(err) : resolve()
          })
        } catch (err) {
          reject(err)
        }
      })
    } catch (e) {
      console.error("Error publishing branch event: ", e)
    }
  }

  const trackBranch = async (
    eventName: string,
    eventProperties?: BranchDataType
  ): Promise<void> => {
    try {
      const branch = await getBranch()

      return new Promise((resolve, reject) => {
        try {
          branch.track(eventName, eventProperties || {}, (err: any) => {
            return err ? reject(err) : resolve()
          })
        } catch (err) {
          reject(err)
        }
      })
    } catch (e) {
      console.error("Error track branch: ", e)
    }
  }

  return (
    <BranchContext.Provider
      value={{
        downloadURL,
        getBranch,
        getBranchBrowserFingerprintId,
        setBranchIdentity,
        publishBranchEvent,
        trackBranch,
      }}
    >
      {children}
    </BranchContext.Provider>
  )
}

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import { Download } from "../buttons"

const JoinDownload = () => {
  const { cyborgImg } = useStaticQuery(graphql`
    query {
      cyborgImg: file(relativePath: { eq: "wait-list-borg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <aside className="join-download">
      <Container>
        <Row>
          <Col xs={12} md>
            <h2>
              Join the <br />
              Ant Colony
            </h2>
          </Col>
          <Col xs={12} md>
            <p>Download the app</p>
            <Download id="footer-download-buttons" />
          </Col>
        </Row>
      </Container>
      <GatsbyImage
        image={cyborgImg.childImageSharp.gatsbyImageData}
        imgStyle={{ objectFit: "contain" }}
        style={{ position: "absolute" }}
        className="cyborg-img"
        alt="Cyborg ant"
      />
    </aside>
  )
}

export default JoinDownload

import React, { useContext } from "react"
import { BranchContext } from "@providers"

import DownloadGoogleWhite from "./download-google-white.svg"
import DownloadGoogleBlack from "./download-google-black.svg"

type Props = {
  className?: string
  type?: string
}

export const ButtonDownloadGoogle = ({ className, type }: Props) => {
  const { downloadURL } = useContext(BranchContext)

  return (
    <div className={className}>
      <a href={downloadURL}>
        {type === "dark" || type === "black" ? (
          <DownloadGoogleBlack />
        ) : (
          <DownloadGoogleWhite />
        )}
      </a>
    </div>
  )
}

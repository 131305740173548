import { gql } from "@apollo/client"
import { mutationPromiseFactory } from "@services"
import { GQLMutationResult, GQLUser, GQLUserWithToken } from "@types"

export const useCreateUser = () => {
  type CreateUserVariables = {
    phone: {
      code: number
      number: string
    }
    email: string
    password: string
  }

  type CreateUserResults = {
    user: GQLUserWithToken
  }

  const mutation = gql`
    mutation CreateUserResult($input: CreateUserInput!) {
      createUser(input: $input) {
        clientMutationId
        errors {
          message
        }

        user {
          id
          dob
          email
          firstName
          lastName
          phone
          phoneConfirmedAt
          registrationCompletedAt
          token
        }
      }
    }
  `

  return mutationPromiseFactory<CreateUserVariables, CreateUserResults>(
    mutation,
    "createUser"
  )
}

export const useUpdateUser = () => {
  type UpdateUserVariables = {
    firstName?: string
    lastName?: string
    email?: string
    password?: string
    passwordConfirmation?: string
    dob?: string
  }

  type UpdateUserResults = {
    user: GQLUser
  }

  const mutation = gql`
    mutation updateUser($input: UpdateUserInput!) {
      updateUser(input: $input) {
        clientMutationId
        errors {
          message
        }
        user {
          id
          dob
          email
          firstName
          lastName
          phone
          phoneConfirmedAt
          registrationCompletedAt
        }
      }
    }
  `
  return mutationPromiseFactory<UpdateUserVariables, UpdateUserResults>(
    mutation,
    "updateUser"
  )
}

export const useConfirmPhone = () => {
  type ConfirmPhoneVariables = {
    confirmationToken: string
  }

  type ConfirmPhoneResults = GQLMutationResult

  const mutation = gql`
    mutation ConfirmPhone($input: ConfirmPhoneInput!) {
      confirmPhone(input: $input) {
        clientMutationId
        errors {
          message
        }
      }
    }
  `

  return mutationPromiseFactory<ConfirmPhoneVariables, ConfirmPhoneResults>(
    mutation,
    "confirmPhone"
  )
}

export const useResendPhoneVerification = () => {
  type ResendPhoneVerificationVariables = {}
  type ResendPhoneVerificationResults = GQLMutationResult

  const mutation = gql`
    mutation ResendPhoneVerification($input: ResendPhoneVerificationInput!) {
      resendPhoneVerification(input: $input) {
        clientMutationId
        errors {
          message
        }
      }
    }
  `

  return mutationPromiseFactory<
    ResendPhoneVerificationVariables,
    ResendPhoneVerificationResults
  >(mutation, "resendPhoneVerification")
}

export const useAddress = () => {
  type AddressVariables = {
    userAddress: {
      address1?: string
      address2?: string
      locality?: string // locality/city
      administrativeArea?: string // state/provence
      postalCode?: string
    }
  }
  type AddressResults = GQLMutationResult

  const mutation = gql`
    mutation Address($input: AddressInput!) {
      address(input: $input) {
        clientMutationId
        errors {
          message
        }
      }
    }
  `

  return mutationPromiseFactory<AddressVariables, AddressResults>(
    mutation,
    "address"
  )
}

export const useRegistrationComplete = () => {
  type RegistrationCompleteVariables = {}
  type RegistrationCompleteResults = GQLMutationResult

  const mutation = gql`
    mutation RegistrationComplete($input: RegistrationCompleteInput!) {
      registrationComplete(input: $input) {
        clientMutationId
        errors {
          message
        }
      }
    }
  `

  return mutationPromiseFactory<
    RegistrationCompleteVariables,
    RegistrationCompleteResults
  >(mutation, "registrationComplete")
}

import React from "react"
import { Form, FormCheckProps } from "react-bootstrap"

type CheckboxProps = FormCheckProps & {
  variant?: string
}

export const Checkbox = (props: CheckboxProps) => {
  const { variant, className, ...propsRest } = props

  const classList = [variant || "primary"]
  props.className && classList.push(props.className)

  return <Form.Check {...propsRest} className={classList.join(" ")} />
}

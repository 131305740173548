import React from "react"
import { Button, ButtonProps } from "react-bootstrap"

import IconSync from "@images/icons/general/Sync.svg"

export type SubmitButtonProps = ButtonProps & {
  isSubmitting?: boolean
}

export const SubmitButton = (props: SubmitButtonProps) => {
  const { isSubmitting, ...bprops } = props
  return (
    <Button
      {...bprops}
      type="submit"
      className={`submit-button ${props.className ? props.className : ""}`}
    >
      {props.isSubmitting ? <IconSync className="spinning" /> : props.children}
    </Button>
  )
}

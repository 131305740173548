import {
  URL_SIGNUP,
  URL_SIGNUP_COMPLETE,
  URL_SIGNUP_GET_STARTED,
  URL_SIGNUP_REGISTER,
  URL_SIGNUP_VERIFY_ADDRESS,
  URL_SIGNUP_VERIFY_IDENTITY,
  URL_SIGNUP_VERIFY_PHONE,
} from "./urls"
import { PageFlow } from "@providers"

// Page Flows
export function getRegistrationPageFlowSettings(isMobile: boolean) {
  const SETTINGS_REGISTRATION_TOTAL_PROGRESSBAR_STEPS = 3

  const STEP_GET_STARTED: PageFlow = {
    pathname: URL_SIGNUP_GET_STARTED,
    allowNoAuth: true,
    progressBar: {
      show: false,
      step: 0,
      totalSteps: 0,
    },
  }

  const SETTINGS_REGISTRATION_FLOW: PageFlow[] = [
    {
      pathname: URL_SIGNUP_REGISTER,
      allowNoAuth: true,
      progressBar: {
        show: false,
        step: 0,
        totalSteps: 0,
      },
    },
    {
      pathname: URL_SIGNUP_VERIFY_PHONE,
      noAuthLocation: URL_SIGNUP,
      progressBar: {
        show: true,
        step: 1,
        totalSteps: SETTINGS_REGISTRATION_TOTAL_PROGRESSBAR_STEPS,
      },
    },
    {
      pathname: URL_SIGNUP_VERIFY_IDENTITY,
      noAuthLocation: URL_SIGNUP,
      progressBar: {
        show: true,
        step: 2,
        totalSteps: SETTINGS_REGISTRATION_TOTAL_PROGRESSBAR_STEPS,
      },
    },
    {
      pathname: URL_SIGNUP_VERIFY_ADDRESS,
      noAuthLocation: URL_SIGNUP,
      progressBar: {
        show: true,
        step: 3,
        totalSteps: SETTINGS_REGISTRATION_TOTAL_PROGRESSBAR_STEPS,
      },
    },
    {
      pathname: URL_SIGNUP_COMPLETE,
      noAuthLocation: URL_SIGNUP,
      progressBar: {
        show: false,
        step: 0,
        totalSteps: SETTINGS_REGISTRATION_TOTAL_PROGRESSBAR_STEPS,
      },
    },
  ]

  if (isMobile) {
    SETTINGS_REGISTRATION_FLOW.unshift(STEP_GET_STARTED)
  }

  return SETTINGS_REGISTRATION_FLOW
}

import React from "react"

import { AntDancingCashMobileImage, Body1, Headline1 } from "@components"
import { Button } from "react-bootstrap"

export type GetStartedInfoProps = {
  isMobile?: boolean
  onClick?: (e: any) => void
}

export const GetStartedInfo = (props: GetStartedInfoProps) => {
  return (
    <div className="get-started-info">
      {props.isMobile && <AntDancingCashMobileImage className="mbs-3" />}
      <Headline1 className="mbs-3">Get paid to match with brands</Headline1>
      <Body1 className="mbs-3">
        The ATM app helps you earn real money by simply interacting with brands.
        Yes, you heard that right!
      </Body1>
      <Body1 className="mbs-3">
        You can also take your ATM account to the next level and invest your
        earnings in the stock market. Start with diversified ETFs, then choose
        from stocks to personalize your portfolio, all from the comfort of the
        ATM app!
      </Body1>
      {!props.isMobile && <div className="get-started-info-img"></div>}
      {props.isMobile && (
        <Button
          variant="primary2"
          className="get-started-info-button"
          onClick={props.onClick}
        >
          Get Started
        </Button>
      )}
    </div>
  )
}

import React from "react"

type Props = {
  className?: string
}

export const AntDancingCashMobileImage = (props: Props) => {
  return (
    <div
      className={`ant-dancing-cash-mobile-img ${props.className || ""}`}
    ></div>
  )
}

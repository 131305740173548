// Screen resposive breakpoints
export const MEDIA_SCREEN_XS = 0
export const MEDIA_SCREEN_SM = 320
export const MEDIA_SCREEN_MD = 672
export const MEDIA_SCREEN_LG = 1056
export const MEDIA_SCREEN_XL = 1312
export const MEDIA_SCREEN_XXL = 1584

// Date
export const MINIMUM_REGISTRATION_AGE = 18

// lengths
export const PHONE_VERIFICATION_TOKEN_LENGTH = 6

import React from "react"

export type StepProgressBarProps = {
  totalSteps: number
  currentStep: number
}

export const StepProgressBar = (props: StepProgressBarProps) => {
  const steps: React.ReactNode[] = []
  for (let i = 1; i <= props.totalSteps; i++) {
    steps.push(
      <div
        key={i}
        className={`step ${i <= props.currentStep ? "mod-active" : ""}`}
      ></div>
    )
  }
  return <div className="step-progress-bar">{steps}</div>
}

import React from "react"

type Props = {
  className?: string
}

export const MainBlockWealthBoostImage = ({ className }: Props) => (
  <div className={className}>
    <picture>
      <source media="(max-width: 1055px)" width="327" height="245" srcSet="/img/2022q3/main-blocks/wealth-boost/mobile.png, /img/2022q3/main-blocks/wealth-boost/mobile@2x.png 2x, /img/2022q3/main-blocks/wealth-boost/mobile@3x.png 3x" />
      <source media="(min-width: 1056px)" width="555" height="416" srcSet="/img/2022q3/main-blocks/wealth-boost/desktop.png, /img/2022q3/main-blocks/wealth-boost/desktop@2x.png 2x, /img/2022q3/main-blocks/wealth-boost/desktop@3x.png 3x" />
      <img src="/img/2022q3/main-blocks/wealth-boost/mobile.png" width="327" height="245" alt="Ants investment chart" />
    </picture>
  </div>
)

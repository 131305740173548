import { TOKEN_USER_REGISTRATION } from "@constants"
import { storageSetItem, storageGetItem, storageRemoveItem } from "@session"

// User registration data
const createUserRegistration = (): UserRegistrationData => {
  return {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    phoneCode: "1",
    password: "",
    dob: "",

    address1: "",
    address2: "",
    locality: "",
    administrativeArea: "",
    postalCode: "",
  }
}

export type UserRegistrationData = {
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  phoneCode?: string
  password?: string
  dob?: string

  address1?: string
  address2?: string
  locality?: string // locality/city
  administrativeArea?: string // state/provence
  postalCode?: string
}

export const storageSetUserRegistration = (data: UserRegistrationData) => {
  const emptyData = createUserRegistration()
  const currentData = storageGetUserRegistration()
  const newData = Object.assign(emptyData, currentData, data)
  newData.password = ""
  storageSetItem(TOKEN_USER_REGISTRATION, newData)
}

export const storageGetUserRegistration = () => {
  const storedData = storageGetItem(TOKEN_USER_REGISTRATION) || {}
  return Object.assign(
    createUserRegistration(),
    storedData
  ) as UserRegistrationData
}

export const storageRemoveUserRegistration = () => {
  storageRemoveItem(TOKEN_USER_REGISTRATION)
}

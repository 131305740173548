import React, { FormEvent, useState, useContext } from "react"
import { Form } from "react-bootstrap"
import {
  TextField,
  Body1,
  Headline1,
  ResendPhoneVerificationCodeLink,
  SuccessToastModal,
  SubmitButton,
} from "@components"
import { formatPhoneNumber } from "@libs"
import {
  useConfirmPhone,
  usePublishWebEvent,
  usePublishBrazeEvent,
} from "@services"
import { storageGetUserRegistration } from "@session"
import { usePageFlow, BranchContext } from "@providers"
import {
  TRACKING_BRANCH,
  TRACKING_BRAZE,
  PHONE_VERIFICATION_TOKEN_LENGTH,
  TRACKING_ATM,
} from "@constants"

export const VerifyPhoneForm = () => {
  const pageFlow = usePageFlow()
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [confirmationToken, setConfirmationToken] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [confirmPhone] = useConfirmPhone()
  const [publishWebEvent] = usePublishWebEvent()
  const [publishBrazeEvent] = usePublishBrazeEvent()
  const { publishBranchEvent } = useContext(BranchContext)

  const storedValues = storageGetUserRegistration()
  const isValid = () =>
    confirmationToken.length === PHONE_VERIFICATION_TOKEN_LENGTH

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (isValid()) {
      publishWebEvent({ name: TRACKING_ATM.button_tap_reg_phone_next })

      setIsSubmitting(true)

      confirmPhone({ confirmationToken })
        .then((data) => {
          publishWebEvent({ name: TRACKING_ATM.screen_view_reg_phone_verified })
          publishBranchEvent(TRACKING_BRANCH.phone_verify)
          publishBrazeEvent(TRACKING_BRAZE.phone_verify)

          setShowModal(true)
          // TODO: remove this timeout and replace with a proper async await or promise
          // time for tracking and viewing of modal
          setTimeout(() => {
            pageFlow.navigateNext()
          }, 2000)
        })
        .catch((errors) => {
          let message = ""
          if (errors && errors.length) {
            const first = errors[0]
            if (first.message === "phone verification token is invalid") {
              message = "Incorrect code"
            } else {
              message = "Server error"
            }
          }
          setErrorMessage(message)
          setIsSubmitting(false)
        })
    }
  }

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Headline1 className="mbs-3">Verify your number</Headline1>
      <Body1 className="mbs-2">
        Enter the 6-digit code sent to you at{" "}
        <strong className="text-nowrap">
          {formatPhoneNumber(storedValues.phone)}
        </strong>
      </Body1>
      <TextField
        className="mbs-3"
        name="code"
        type="number"
        label="6 digit code*"
        placeholderLabel
        touched={true}
        error={errorMessage}
        isInvalid={!!errorMessage}
        onChange={(e) =>
          setConfirmationToken(
            e.target.value.slice(0, PHONE_VERIFICATION_TOKEN_LENGTH)
          )
        }
        value={confirmationToken}
        maxLength={8}
      />
      <div className="mbs-3">
        <ResendPhoneVerificationCodeLink
          onSuccess={() => {
            publishWebEvent({
              name: TRACKING_ATM.screen_view_reg_phone_resend_code,
            })
          }}
        />
      </div>

      <SubmitButton
        disabled={!isValid() || isSubmitting}
        variant="primary2"
        isSubmitting={isSubmitting}
      >
        Next
      </SubmitButton>

      <SuccessToastModal show={showModal} text="Phone verified" />
    </Form>
  )
}

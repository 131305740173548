import React, { useContext } from "react"
// import { initializeApp } from "firebase/app"
// import { getAnalytics } from "firebase/analytics"
// import { getPerformance } from "firebase/performance"

// const config = {
//   apiKey: process.env.FIREBASE_API_KEY,
//   authDomain: process.env.FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.FIREBASE_PROJECT_ID,
//   storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.FIREBASE_APP_ID,
//   measurementId: process.env.FIREBASE_MEASUREMENT_ID,
// }

// Initialize Firebase
// let app
// let performance
// let analytics
// let defaultContext = {}

// if (isBrowser) {
//   app = initializeApp(config)
//   performance = getPerformance(app)
//   analytics = getAnalytics(app)
//   // Add SDKs for Firebase products that you want to use
//   // https://firebase.google.com/docs/web/setup#available-libraries
//   defaultContext = { app, config, performance, analytics }
// }

//export const FirebaseContext = React.createContext(defaultContext)

export const FirebaseProvider = (props: React.PropsWithChildren<{}>) => {
  return null
  // return (
  //   <FirebaseContext.Provider value={defaultContext}>
  //     {props.children}
  //   </FirebaseContext.Provider>
  // )
}

export const useFirebase = () => {
  //return useContext(FirebaseContext)
}

import {
  REGEX_PASSWORD,
  REGEX_PHONE,
  REGEX_EMAIL,
  REGEX_URL,
  MINIMUM_REGISTRATION_AGE,
  REGEX_ZIPCODE,
} from "@constants"

export const isEmail = (email: string) => {
  return REGEX_EMAIL.test(email)
}

export const isPassword = (password: string) => {
  return REGEX_PASSWORD.test(password)
}

export const isPhone = (phone: string) => {
  return REGEX_PHONE.test(phone)
}

export const isURL = (url: string) => {
  return REGEX_URL.test(url)
}

export const isRegistrationAgeValid = (dob?: string) => {
  if (!dob) return false

  let [year, month, day] = dob.split("-")
  if (!(year && month && day)) {
    return false
  }

  const minDate = new Date()
  minDate.setFullYear(minDate.getFullYear() - MINIMUM_REGISTRATION_AGE)
  const bday = new Date(Number(year), Number(month) - 1, Number(day))

  return bday < minDate
}

export const isZipCode = (zipCode?: string) => {
  if (!zipCode) return false

  return REGEX_ZIPCODE.test(zipCode)
}

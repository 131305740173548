import React from "react"

type Props = {
  className?: string
}

export const MainBlockPressLogoImage = ({ className }: Props) => (
  <div className={className}>
    <picture>
      <source
        width="320"
        height="113"
        srcSet="/img/press/atm-logo@2x.png 2x, /img/press/atm-logo@3x.png 3x, /img/press/atm-logo@4x.png 4x"
      />
      <img
        src="/img/press/atm-logo.png"
        width="320"
        height="113"
        alt="ATM.com Logo"
      />
    </picture>
  </div>
)

export const TRACKING_BRANCH = {
  // Web Registration
  registration: "registration",
  registration_complete: "registration_complete",
  phone_verify: "phone_verify",
  identity_verify: "identity_verify",
}

export const TRACKING_BRAZE = {
  // Web Registration
  registration: "registration",
  registration_complete: "registration_complete",
  phone_verify: "phone_verify",
  identity_verify: "identity_verify",
}

export const TRACKING_ATM = {
  // Download Only
  screen_view_download: "screen_view.download",
  button_tap_download_ios_cta: "button_tap.download_ios_cta",
  button_tap_download_android_cta: "button_tap.download_android_cta",
  // Web Registration
  screen_view_get_started: "screen_view.get_started",
  screen_view_reg: "screen_view.reg",
  button_tap_reg_next: "button_tap.reg_next",
  screen_view_reg_email_modal: "screen_view.reg_email_modal",
  button_tap_reg_email_next: "button_tap.reg_email_next",
  screen_view_reg_phone: "screen_view.reg_phone",
  screen_view_reg_phone_resend_code: "screen_view.reg_phone_resend_code",
  button_tap_reg_phone_next: "button_tap.reg_phone_next",
  screen_view_reg_phone_verified: "screen_view.reg_phone_verified",
  screen_view_reg_identity: "screen_view.reg_identity",
  button_tap_reg_identity_next: "button_tap.reg_identity_next",
  screen_view_reg_address: "screen_view.reg_address",
  button_tap_reg_address_next: "button_tap.reg_address_next",
  screen_view_reg_success: "screen_view.reg_success",
  button_tap_reg_ios_cta: "button_tap.reg_ios_cta",
  button_tap_reg_android_cta: "button_tap.reg_android_cta",
}

export const DEEPVIEW_BRANCH = {
  desktop_default: "atm.com_deepview_rwjb",
}

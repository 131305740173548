import React, { useEffect, useState, useContext } from "react"
import { Row, Col } from "react-bootstrap"
import isMobile from "ismobilejs"
import { isBrowser } from "@libs"
import { BranchContext } from "@providers"

import GooglePlay from "../../../static/svg/download-google-play.svg"
import AppStore from "../../../static/svg/download-app-store.svg"

type DownloadProps = {
  id?: string
  style?: React.CSSProperties
  showBothButtons?: boolean
}

const Download = ({ id, style, showBothButtons }: DownloadProps) => {
  const { downloadURL } = useContext(BranchContext)
  const [platform, setPlatform] = useState(0)

  useEffect(() => {
    if (isBrowser) {
      const updatePlatform = () => {
        const { userAgent } = navigator || {}

        if (userAgent) {
          if (showBothButtons) {
            setPlatform(0)
          } else if (isMobile(userAgent).android.phone) {
            setPlatform(2)
          } else if (isMobile(userAgent).apple.phone) {
            setPlatform(1)
          } else {
            setPlatform(0)
          }
        }
      }

      window.addEventListener("resize", updatePlatform)
      updatePlatform()

      return () => window.removeEventListener("resize", updatePlatform)
    }
  }, [showBothButtons])

  const isIOS = [0, 1].includes(platform)
  const isAndroid = [0, 2].includes(platform)

  return (
    <Row style={style} id={id} className="download-buttons">
      {isIOS && (
        <Col xs className="app-store">
          <a href={downloadURL} title="Download this earn money app on iOS">
            <AppStore
              width="182"
              height="59"
              style={{ maxWidth: "100%", alignSelf: "center" }}
            />
          </a>
        </Col>
      )}

      {isAndroid && (
        <Col xs className="google-play">
          <a href={downloadURL} title="Download this earn money app on Android">
            <GooglePlay
              width="182"
              height="57"
              style={{ maxWidth: "100%", alignSelf: "center" }}
            />
          </a>
        </Col>
      )}
    </Row>
  )
}

export default Download

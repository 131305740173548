import React, { useContext } from "react"
import { BranchContext } from "@providers"

type Props = {
  className?: string
  secondary?: boolean
  primary?: boolean
  text?: string
}

export const ButtonGetTheApp = ({
  className,
  secondary,
  primary,
  text,
}: Props) => {
  const { downloadURL } = useContext(BranchContext)

  return (
    <div className={`button-get-the-app ${className || ""}`}>
      <a
        href={downloadURL}
        className={`btn btn-download btn-elevate ${
          primary ? "btn-primary" : ""
        } ${secondary ? "btn-secondary" : ""}`}
      >
        {text || "Get the App"}
      </a>
    </div>
  )
}

// URLS
export const URL_SIGNUP = "/signup/"
export const URL_SIGNUP_GET_STARTED = `${URL_SIGNUP}get-started/`
export const URL_SIGNUP_REGISTER = `${URL_SIGNUP}register/`
export const URL_SIGNUP_VERIFY_PHONE = `${URL_SIGNUP}verify-phone/`
export const URL_SIGNUP_VERIFY_IDENTITY = `${URL_SIGNUP}verify-identity/`
export const URL_SIGNUP_VERIFY_ADDRESS = `${URL_SIGNUP}verify-address/`
export const URL_SIGNUP_COMPLETE = `${URL_SIGNUP}complete/`

export const URL_ATM_PRIVACY_POLICY = "/privacy-policy/"
export const URL_ATM_TERMS = "/terms-of-service/"
export const URL_ATM_GET_THE_APP = "/get-atm-app/"

export const URL_BRANCH_WEB_TO_APP = "https://atm.app.link/download"

export const URL_APP_STORE =
  "https://apps.apple.com/us/app/atm-com-earn-money/id1507829573"
export const URL_GOOGLE_PLAY =
  "https://play.google.com/store/apps/details?id=com.atm.atm.prod&hl=en_US&gl=US"

import React from "react"
import { Button, ModalProps } from "react-bootstrap"

import { Body1, Headline3, Modal, SubHeadline } from "@components"

export interface EmailCorrectModalProps extends ModalProps {
  email: string
  onCanceled: () => void
  onConfirmed: () => void
}

export const EmailCorrectModal = (props: EmailCorrectModalProps) => {
  return (
    <Modal
      className="email-correct-modal"
      onHide={props.onHide}
      show={props.show || false}
    >
      <div className="add-data-added-img mbs-2"></div>
      <Headline3 className="mbs-2">Is your email correct?</Headline3>
      <Body1 className="mbs-1">
        We’ll be sending important account updates and information to
      </Body1>
      <SubHeadline>{props.email}</SubHeadline>
      <div className="actions">
        <Button
          className="actions-button"
          variant="outline-primary2"
          onClick={props.onCanceled}
        >
          Change
        </Button>
        <Button
          className="actions-button"
          variant="primary2"
          onClick={props.onConfirmed}
        >
          Looks Good
        </Button>
      </div>
    </Modal>
  )
}

import { MutationResult, useMutation } from "@apollo/client"

import { GQLDocument, TApolloData, TApolloVariables } from "@types"

export type MutationTuple<TVariables, TResults> = [
  (variables: TVariables) => Promise<TResults>,
  MutationResult<TApolloData<TResults>>
]

export function mutationPromiseFactory<TVariables, TResults>(
  mutation: GQLDocument<TVariables, TResults>,
  dataKey: string
): MutationTuple<TVariables, TResults> {
  const [_mutation, data] = useMutation<
    TApolloData<TResults>,
    TApolloVariables<TVariables>
  >(mutation)

  return [
    (variables: TVariables | undefined = undefined) => {
      return new Promise<TResults>((resolve, reject) => {
        _mutation({
          variables: { input: variables || {} },
          onCompleted: (data) => {
            const d = data[dataKey]

            if ("errors" in d && d.errors?.length) {
              reject(d.errors)
            } else {
              resolve(d)
            }
          },
          onError: (error) => {
            reject([error])
          },
        })
      })
    },
    data,
  ]
}

export * from "./tracking"
export * from "./users"

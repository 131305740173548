import React from "react"

type Props = {
  className?: string
}

export const MainBlockAboutWhoWeAreImage = ({ className }: Props) => (
  <div className={className}>
    <picture>
      <source
        width="505"
        height="302"
        srcSet="/img/2022q3/main-blocks/about-who-we-are/desktop.jpg, /img/2022q3/main-blocks/about-who-we-are/desktop@2x.jpg 2x, /img/2022q3/main-blocks/about-who-we-are/desktop@3x.jpg 3x"
      />
      <img
        src="/img/2022q3/main-blocks/about-who-we-are/desktop.jpg"
        width="505"
        height="302"
        alt="ATM team"
      />
    </picture>
  </div>
)

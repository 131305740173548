import React, { useState, useContext } from "react"
import { Form, Alert } from "react-bootstrap"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
// atm packages
import {
  TextField,
  Body1,
  GQLErrorList,
  Headline1,
  SubmitButton,
} from "@components"
import { isRegistrationAgeValid } from "@libs"
import {
  usePublishWebEvent,
  usePublishBrazeEvent,
  useUpdateUser,
} from "@services"
import {
  storageGetUserRegistration,
  storageSetUserRegistration,
} from "@session"
import { usePageFlow, BranchContext } from "@providers"
import { TRACKING_ATM, TRACKING_BRANCH, TRACKING_BRAZE } from "@constants"

const formSchema = yup.object().shape({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  dob: yup
    .string()
    .required("Required")
    .test({
      name: "dob-valid",
      message: "You must be 18 years or older to use this app",
      test: (value) => {
        return isRegistrationAgeValid(value)
      },
    }),
})

export const PersonalInformationForm = () => {
  const pageFlow = usePageFlow()
  const [updateUser] = useUpdateUser()
  const [publishWebEvent] = usePublishWebEvent()
  const [publishBrazeEvent] = usePublishBrazeEvent()
  const { publishBranchEvent } = useContext(BranchContext)

  const [errorMessage, setErrorMessage] = useState<null | React.ReactNode>(null)

  const storedValues = storageGetUserRegistration()
  const initialValues = {
    firstName: storedValues.firstName || "",
    lastName: storedValues.lastName || "",
    dob: storedValues.dob || "",
  }

  const {
    control,
    handleSubmit,
    formState: { errors, touchedFields, isValid, isSubmitting },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    criteriaMode: "all",
    defaultValues: initialValues,
    resolver: yupResolver(formSchema),
  })

  const handleOnSubmit = (values: any) => {
    return new Promise<void>((resolve, reject) => {
      const storedValues = Object.assign({}, values)
      storageSetUserRegistration(storedValues)

      publishWebEvent({
        name: TRACKING_ATM.button_tap_reg_identity_next,
      })

      updateUser({
        firstName: values.firstName,
        lastName: values.lastName,
        dob: values.dob,
      })
        .then(() => {
          publishBranchEvent(TRACKING_BRANCH.identity_verify)
          publishBrazeEvent(TRACKING_BRAZE.identity_verify)
          pageFlow.navigateNext()
        })
        .catch((errors) => {
          setErrorMessage(<GQLErrorList errors={errors} />)
          resolve()
        })
    })
  }

  return (
    <Form
      noValidate
      onSubmit={handleSubmit(async (data) => await handleOnSubmit(data))}
    >
      <Headline1 className="mbs-3">Verify your identity</Headline1>
      <Body1 className="mbs-2">We need a few more details from you...</Body1>

      {errorMessage && (
        <Alert variant="danger" className="mbs-3">
          {errorMessage}
        </Alert>
      )}

      <Controller
        name="firstName"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Legal first name*"
            placeholderLabel
            className="mbs-2"
            isInvalid={touchedFields.firstName && !!errors.firstName}
            error={errors.firstName?.message}
            touched={touchedFields.firstName}
          />
        )}
      />

      <Controller
        name="lastName"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Legal last name*"
            className="mbs-2"
            placeholderLabel
            isInvalid={touchedFields.lastName && !!errors.lastName}
            error={errors.lastName?.message}
            touched={touchedFields.lastName}
          />
        )}
      />

      <Controller
        name="dob"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="date"
            label="Date of birth*"
            className="mbs-2"
            placeholder="Month / Day / Year"
            placeholderLabel
            isInvalid={touchedFields.dob && !!errors.dob}
            error={errors.dob?.message}
            touched={touchedFields.dob}
          />
        )}
      />

      <SubmitButton
        disabled={isSubmitting || !isValid}
        variant="primary2"
        className="mts-3"
        isSubmitting={isSubmitting}
      >
        Next
      </SubmitButton>
    </Form>
  )
}

import React from "react"
import SEO from "@components/seo/index"

export default function SEODefault() {
  return (
    <SEO
      overrideTitleTemplate={`%s`}
      title="Earn Money with ATM.com - Earn Cash, Save, and Invest With The ATM App"
      description="Earn money with the ATM.com® app matching with top brands! Our unique Brand Matching feature gives you the ability to earn extra cash using your phone!"
    />
  )
}

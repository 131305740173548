/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
const React = require("react")
const { storageInitQueryKeys } = require("@session")
const {
  ApolloClientProvider,
  BranchProvider,
  BrazeProvider,
} = require("@providers")
const { RouteChangeTracker } = require("@components")

// Load Rubik typeface
require("typeface-inter")

exports.wrapRootElement = ({ element }) => {
  storageInitQueryKeys()
  return (
    <ApolloClientProvider>
      <BrazeProvider>
        <BranchProvider>
          <RouteChangeTracker>{element}</RouteChangeTracker>
        </BranchProvider>
      </BrazeProvider>
    </ApolloClientProvider>
  )
}

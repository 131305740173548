import { GQLError } from "@types"
import React from "react"

type GQLErrorListProps = {
  errors: GQLError[]
}

export const GQLErrorList = (props: GQLErrorListProps) => {
  if (props.errors === null) {
    return null
  }

  return (
    <ul className="m-0">
      {props.errors.map((error: GQLError, i: number) => (
        <li key={i}>{error.message}</li>
      ))}
    </ul>
  )
}

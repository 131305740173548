import React, { useEffect, useContext } from "react"
import { globalHistory } from "@reach/router"
import { BranchContext } from "@providers"
import { isBrowser } from "@libs"

export const RouteChangeTracker: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const { trackBranch } = useContext(BranchContext)

  useEffect(() => {
    if (isBrowser) {
      const removeListener = globalHistory.listen(({ action }) => {
        if (action === "PUSH") {
          trackBranch("pageview")
        }
      })

      return () => {
        removeListener()
      }
    }
  }, [])

  return <>{children}</>
}

import React from "react"
import { ModalProps } from "react-bootstrap"

import { SubHeadline, ToastModal } from "@components"
import IconCheckmarkCircleOutlined from "@images/icons/general/CheckmarkCircleOutlined.svg"

export type SuccessToastModalProps = ModalProps & {
  text: string
}

export const SuccessToastModal = (props: SuccessToastModalProps) => {
  return (
    <ToastModal {...props} show={props.show || false} className="mod-success">
      <div className="text-center">
        <IconCheckmarkCircleOutlined className="icon" />
        <SubHeadline>{props.text}</SubHeadline>
      </div>
    </ToastModal>
  )
}
